.po-records {
    padding: 2%;
}

.po-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
