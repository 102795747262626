.logo {
    width: 90%;
}

.logo-container {
    display: flex;
    justify-content: center;
    width: 10%;
}

.right-space {
    width: 10%;
}